import { HttpContentData } from "@/services"

//准备state：用于存储数据
const state = {
    contentData: []
}

const getters = {
    getAll(state) {
        return state.contentData.data
    },
    getAnswerAccuracy(state) {
        return state.contentData.data?.answerAccuracy
    },
    getBrowsePeopleNum(state) {
        return state.contentData.data?.browsePeopleNum
    },
    getDevices(state) {
        return state.contentData.data?.devices
    },
    getParticipantNum(state) {
        return state.contentData.data?.participantNum
    },
    getProvinceAnswerList(state) {
        return state.contentData.data?.provinceAnswerList
    },
    getProvinceDeviceList(state) {
        return state.contentData.data?.provinceDeviceList
    },
    getProvinceList(state) {
        return state.contentData.data?.provinceList
    },
    getReceivePrizeRecord(state) {
        return state.contentData.data?.receivePrizeRecord
    },
    getExamDefAccuracyList(state) {
        return state.contentData.data?.examDefAccuracyList
    },
    getExamDefNameList(state) {
        return state.contentData.data?.examDefNameList
    }
}

//准备mutations：用于操作数据
const mutations = {
    setContent(state, ploay) {
        state.contentData = ploay
    }
}

//准备actions：用于响应组件中的动作
const actions = {
    async FetchContentAction(context) {
        const contentData = await HttpContentData()
        console.log(contentData, 27);
        context.commit("setContent", contentData)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}