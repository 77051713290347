<template>
  <div id="app">
    <!-- <img src="@/assets/img/标注-首页.jpg" alt="" /> -->
    
    <!-- <el-button type="primary">主要按钮</el-button>
    <el-button type="success">成功按钮</el-button> -->
    <RouterView></RouterView>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
export default {
  name: "app",
  components: { RouterView },
};
</script>

<style scoped lang="less">
#app {
  img {
    position: absolute;
    z-index: -1;
    width: 100%;
  }
}
</style>
