import Vue from 'vue'
import App from '@/App.vue'

import router from '@/router'
import store from '@/stores'

Vue.config.productionTip = false


import { CarouselItem, Carousel, Button, Progress } from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/index.css";

import 'echarts-gl' // 3d图表库;


Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Progress);


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
