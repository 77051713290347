import VueRouter from 'vue-router'

import Vue from 'vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "hash",
    routes: [
        {
            path: "/",
            redirect: "/index",
        },
        {
            name: "/index",
            path: "/index",
            component: () => import('@/views/home/home.vue')
        },
        {
            name: "/city",
            path: "/city",
            component: () => import("@/views/city/city.vue")
        },
        {
            path: "/district",
            component: () => import("@/views/district/district.vue")
        },
        {
            name: "/xizang-city",
            path: "/xizang-city",
            component: () => import("@/views/city/xizang-city.vue")
        },
        {
            path: "/chongzhou-district",
            component: () => import("@/views/district/chongzhou-district.vue")
        }
    ]
})


export default router